import React, { useState } from 'react'

import Base from './_Base'

import { servicesLiveMaintenance as slideData } from '../../../../constants/ServicesPage'

import Icon1 from '../../../../../static/services/icon/liveAndMaintenance-release.svg'
import Icon2 from '../../../../../static/services/icon/liveAndMaintenance-deployment.svg'
import Icon3 from '../../../../../static/services/icon/liveAndMaintenance-ongoing-support.svg'

export default () => {
  const [icons] = useState([
    <Icon1/>,
    <Icon2/>,
    <Icon3/>,
  ])

  return <Base {...{ slideData, icons }}/>
}
