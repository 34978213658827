import React from 'react'
import * as Carousel from 'pure-react-carousel'

import Gallery from '../../../../components/gallery/Gallery'

import '../../../../styles/pages/services/mobile/serviceGallery.scss'

export default ({ portfolioItems }) => {
  // remove items for all portfolioItems
  portfolioItems.forEach(
    portfolioItem => portfolioItem.items = null
  )

  return (
    <Carousel.CarouselProvider
      infinite={ true }
      isIntrinsicHeight={ true }
      totalSlides={ portfolioItems.length }
    >

      <Carousel.Slider>
        { portfolioItems.map((portfolioItem, index) =>
          <Carousel.Slide key={ index }>
            <Gallery portfolioItems={ [portfolioItem] } alwaysActive/>
          </Carousel.Slide>
        ) }
      </Carousel.Slider>

      <div className='carousel__navigation'>
        <Carousel.ButtonBack>
          <span className="carousel__button-arrow left"></span>
        </Carousel.ButtonBack>

        <Carousel.DotGroup/>

        <Carousel.ButtonNext>
          <span className="carousel__button-arrow right"></span>
        </Carousel.ButtonNext>
      </div>

    </Carousel.CarouselProvider>
  )
}
