import React, { useState } from 'react'

import Base from './_Base'

import { servicesFeaturedData as slideData } from '../../../../constants/ServicesPage'

import Icon1 from '../../../../../static/services/icon/featured-security.svg'
import Icon2 from '../../../../../static/services/icon/featured-qa.svg'
import Icon3 from '../../../../../static/services/icon/featured-speed-optimisation.svg'

export default () => {
  const [icons] = useState([
    <Icon1/>,
    <Icon2/>,
    <Icon3/>,
  ])

  return <Base {...{ slideData, icons }}/>
}
