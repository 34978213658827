import React from 'react'

import Gallery from '../../../../components/gallery/Gallery'

import '../../../../styles/pages/services/desktop/serviceGallery.scss'

export default ({ portfolioItems }) => {
  // deep clone object
  portfolioItems = JSON.parse(JSON.stringify(portfolioItems))

  // remove items for not first portfolioItems
  portfolioItems.forEach((portfolioItem, index) =>
    (index !== 0) && (portfolioItem.items = null)
  )

  return (
    <Gallery
      {...{ portfolioItems }}
      columnsCount={ 2 }
      cardsMap={`
        2 1
        0 1
      `}
    />
  )
}
