import React, { useState, useCallback } from 'react'

import { HeaderSection } from './HeaderSection'
import ServiceGallery from './ServiceGallery'
import { MainContent } from './MainContent'

import '../../../../styles/pages/services/desktop/_services-header.scss'
import '../../../../styles/pages/services/desktop/_services-main-content.scss'
import '../../../../styles/pages/services/desktop/_services-navigation.scss'

export const ServicesDesktop = ({ navigationServicesData } ) => {
  const [isOpenPortfolio, setIsOpenPortfolio] = useState(false)

  // open portfolio
  const handleToggleSwitch = useCallback(() => {
    setIsOpenPortfolio(openPortfolio => !openPortfolio)
  }, [])

  return (
    <div className="services-desktop">
      <HeaderSection
        handleToggleSwitch={ handleToggleSwitch }
        headerData={ navigationServicesData.headerData }
      />
      <div className="services-desktop-main">
        { isOpenPortfolio ?
          <ServiceGallery portfolioItems={ navigationServicesData.portfolioCardList }/> :
          <MainContent categoryList={ navigationServicesData.servicesCategoryList }/>
        }
      </div>
    </div>
  )
}
