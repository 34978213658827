import React, { useState } from 'react'

import Base from './_Base'

import { servicesBackEndData as slideData } from '../../../../constants/ServicesPage'

import Icon1 from '../../../../../static/services/icon/backend-modern-framework.svg'
import Icon2 from '../../../../../static/services/icon/backend-clean-well-structure-code.svg'
import Icon3 from '../../../../../static/services/icon/backend-advanced-tool.svg'

export default () => {
  const [icons] = useState([
    <Icon1 className='symfony-icon'/>,
    <Icon2/>,
    <Icon3/>,
  ])

  return <Base {...{ slideData, icons }}/>
}
