import React, { useMemo } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import { ServicesMobile } from '../mobile/ServicesMobile'
import { ServicesDesktop } from '../desktop/ServicesDesktop'

import { portfolioItems } from '../../../../constants'

export default ({ slideData, icons }) => {
  const breakpoints = useBreakpoint()

  const navigationServicesData = useMemo(() => ({
    ...slideData,

    servicesCategoryList: slideData.servicesCategoryList
      .map((item, index) => ({ ...item, icon: icons[index] })),

    portfolioCardList: slideData.portfolioCardList.map(title =>
      portfolioItems.find(portfolioItem => portfolioItem.title === title))
  }), [])

  return (
    <div className="section services-section">
      <div>
        { breakpoints.md ?
          <ServicesMobile {...{ navigationServicesData }}/> :
          <ServicesDesktop {...{ navigationServicesData }}/>
        }
      </div>
    </div>
  )
}
