import React, { useState } from 'react'

import Base from './_Base'

import { servicesApiData as slideData } from '../../../../constants/ServicesPage'

import Icon1 from '../../../../../static/services/icon/api-integration.svg'
import Icon2 from '../../../../../static/services/icon/backend-clean-well-structure-code.svg'
import Icon3 from '../../../../../static/services/icon/api-development.svg'

export default () => {
  const [icons] = useState([
    <Icon1/>,
    <Icon2/>,
    <Icon3/>
  ])

  return <Base {...{ slideData, icons }}/>
}
