export const navigationListData = [
  'Discovery',
  'UI/UX design',
  'Frontend',
  'Backend',
  'API',
  'Live & Maintenance',
  'Featured'
]

export const servicesDiscoveryData = {
  headerData: {
    title: 'Discovery',
    description: `Together with Business Analyst and Lead Developer,
      we define implementation approach,
      functional requirements, and detailed development plan,
      according to your budget, desired timeline and business goals.`,
  },
  servicesCategoryList: [
    {
      title: 'Business Analytics',
      text: `
        We help transform your ideas and business goals into functional requirements.
        Our specialist analyzes business processes and researches available automations.
        Well done architecture allows to code effectively and reduce the budget.
      `
    },
    {
      title: 'Feasibility and Architecture',
      text: `
        Feasibility study helps to find an optimal way to
        accomplish complex tasks and integrations with 3rd party components.
        It’s important to eliminate technical risks and search for alternatives
        before start to get succedded.
    `
    },
    {
      title: 'Specifications Creation',
      text: `
        We create a document, which describes web application structure,
        functionality, user roles, and workflow. Based on specification,
        we make a detailed development plan, caclulate time and budget costs,
        after which we can begin coding.
    `
    }
  ],
  portfolioCardList: [
    'Auto Repair ERP',
    'Monitoring Dashboard',
    'Hotel Booking',
  ],
}

export const UiUxDesignData = {
  headerData: {
    title: 'UI/UX design',
    description: `Our design team will design
      User Interface according to your vision and business
      goals and foresee all use cases including main user
      stories, negative use cases, empty states and wrong data.`,
  },
  servicesCategoryList: [
    {
      title: 'Wireframes',
      text: `
      Creating set of schematic interface pages to demonstrate the workflow,
      navigation, page layout.
      `
    },
    {
      title: 'Graphical design',
      text: `
        Our designers focus on creating intuitive and user friendly interfaces.
        Additionally we monitor aligning design and specification to make sure
        that UI/UX leverage required level of functionality.
    `
    },
    {
      title: 'Interactive UI prototype',
      text: `
        Creating interactive prototype based on Wireframes or Design pages to
        demonstrate the workflow and interactions.
    `
    }
  ],
  portfolioCardList: [
    'Hotel Booking',
    'Baby Gift List',
    'Security SaaS software',
  ],
}

export const servicesFrontEndData = {
  headerData: {
    title: 'Frontend',
    description: `
      Deliver great User Experience with lightning fast and interactive
      frontend with modern JS frameworks like Vue and React.`,
  },
  servicesCategoryList: [
    {
      title: 'Responsive HTML/CSS',
      text: `
        We will create responsive HTML markup for Mobile, Tablet and Desktop
        devices and popular browsers.`
    },
    {
      title: 'Modern Javascript frameworks',
      text: `
        We use Vue.js and React.js frameworks to build rich and functional
        frontend. It's powerful tools to build ERP/CRM solutions, dashboards or
        SaaS with data visualization and charts.`
    },
    {
      title: 'SPA & PWA applications',
      text: `
        Modern technologies allows to create Single Page and Progressive Web
        Applications with easy and fast navigation, lots of interactive elements and
        powerful features`
    },
    {
      title: 'Templates & UI kits',
      text: `
        Usage of ready themes and UI kits allows to speed up the creation of
        dashboards and admin panels.`
    }
  ],
  portfolioCardList: [
    'Security SaaS software',
    'Vocalists Social Network',
    'Monitoring Dashboard',
  ],
}

export const servicesBackEndData = {
  headerData: {
    title: 'Backend',
    description: `
      Team of professional PHP developers. Strong specialisation allows us to solve
      wide range of tasks from building simple CMS solutions to large ERP systems.`,
  },
  servicesCategoryList: [
      {
        title: 'Modern frameworks',
        text: `
          We use time proven technologies including PHP7 and Symfony framework.
          This stack is balanced of performance, scalability and development costs.
        `
      },
      {
        title: 'Clean well-structured code',
        text: `
          We produce robust and commented code, follow PSR and OOP standards
          and have senior developers who build the architecture and control quality.
      `
      },
      {
        title: 'Advanced tools',
        text: `
          Implementing complex features like full text search, pdf generation, image
          and video converting, web parsing using external tools.
      `
      }
    ],
  portfolioCardList: [
    'Auto Repair ERP',
    'Monitoring Dashboard',
    'Online Store',
  ],
}

export const servicesApiData = {
  headerData: {
    title: 'API',
    description: `
      Extend functionality of your web app with 3rd party
      integrations, sync work of
      different services or create your own API for mobile apps or another services.`,
  },
  servicesCategoryList: [
    {
      title: 'API integrations',
      text: `
        We've built more than 100 API integrations with different services including
        Payment Gateways, CRM, hotel channels and marketing automation tools.`
    },
    {
      title: 'API development',
      text: `
        We can build internal and external API backend. Internal API can be used
        for iOS/Android apps and JS based frontend. External API will provide
        access to the data for your partners or users. We use Swagger and
        Postman collections.`
    },
    {
      title: 'API connections',
      text: `
        We can build middle layer backend to sync data between different 3rd
        party services, for instance, Facebook Marketing API and analytics
        dashboard.`
    }
  ],
  portfolioCardList: [
    'Security SaaS software',
    'ERP Product API',
    'eBay Integration',
  ],
}

export const servicesLiveMaintenance = {
  headerData: {
    title: 'Live & Maintenance',
    description: `
      We will help you to prepare for release and provide you with on-going
      development services and maintenance for long term growth.`,
  },
  servicesCategoryList: [
    {
      title: 'Release preparation',
      text: `
        We will prepare full functioning demo for
        your review and check before release.`
    },
    {
      title: 'Deployment',
      text: `
        We will setup hosting, domain, connect third-party services, add SEO tags
        and analytics tools. Additionally we can do full QA and migrate your data.`
    },
    {
      title: 'On-going support',
      text: `
        We will fix reported issues shortly and make sure the web app is functioning
        smoothly. Our team is available after release and will help you to improve
        your website and extend the functionality.
    `
    }
  ],
  portfolioCardList: [
    'Language Schools',
    'Schools Directory',
    'Video Stock Website',
  ],
}

export const servicesFeaturedData = {
  headerData: {
    title: 'Featured',
    description: `We focus on high quality and attention to the details
      and you will be able to take advantage of additional services we provide.`,
  },
  servicesCategoryList: [
    {
      title: 'Speed optimisation',
      text: `
        Optimization of HTML, CSS, JavaScript for Google Insights scoring. You
        will get faster loading and higher rankings in search indexes.
    `
    },
    {
      title: 'QA',
      text: `
        Our QA department will develop test strategy and test cases, cover
        functionality with documentation and do test runs to keep high quality and
        smooth releases.
    `
    },
    {
      title: 'Security',
      text: `
        In-built preventing common security vulnerabilities like cross site scripting (XSS),
        cross site request forgery (CSRF), SQL injection, authentication bypass.
      `
    },
  ],
  portfolioCardList: [
    'Hotel Booking',
    'Music lessons',
    'Security SaaS software',
  ]
}
