import React, { useState } from 'react'

import Base from './_Base'

import { UiUxDesignData as slideData} from '../../../../constants/ServicesPage'

import Icon1 from '../../../../../static/services/icon/ui-ux-wireframes-desktop.svg'
import Icon2 from '../../../../../static/services/icon/ui-ux-graphic-design.svg'
import Icon3 from '../../../../../static/services/icon/ui-ux-interactive-ui.svg'

export default () => {
  const [icons] = useState([
    <Icon1 className='wireframe-icon'/>,
    <Icon2/>,
    <Icon3/>,
  ])

  return <Base {...{ slideData, icons }}/>
}
