import React, { useState } from 'react'

import Base from './_Base'

import { servicesDiscoveryData as slideData } from '../../../../constants/ServicesPage'

import Icon1 from '../../../../../static/services/icon/discovery-business-analytics-icon.svg'
import Icon2 from '../../../../../static/services/icon/discovery-feasibility-investigation-icon.svg'
import Icon3 from '../../../../../static/services/icon/discovery-specification-creation-icon.svg'

export default () => {
  const [icons] = useState([
    <Icon1/>,
    <Icon2/>,
    <Icon3/>,
  ])

  return <Base {...{ slideData, icons }}/>
}
