import React, { useState } from 'react'

import Base from './_Base'

import { servicesFrontEndData as slideData } from '../../../../constants/ServicesPage'

import Icon1 from '../../../../../static/services/icon/frontend-responsive.svg'
import Icon2 from '../../../../../static/services/icon/frontend-modern-framework.svg'
import Icon3 from '../../../../../static/services/icon/frontend-spa-pwa.svg'
import Icon4 from '../../../../../static/services/icon/frontend-templates-ui-kit.svg'

export default () => {
  const [icons] = useState([
    <Icon1/>,
    <Icon2/>,
    <Icon3/>,
    <Icon4/>,
  ])

  return <Base {...{ slideData, icons }}/>
}
